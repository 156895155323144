import React from 'react';
import { LayoutProvider } from '../components/layout';
import ReactMarkdown from 'react-markdown';
import IPage from '../@types/IPage';
import { RoundedButton } from '../atoms/Button';
import { CenteredContainer, ResponsiveContainer } from '../atoms/Container';
import { cannonStyled, NSH3, useCannonStyletron } from '../theme';
import { CannonBreadcrumbs, DocumentBody } from '../atoms';
import { useTableOrMobile } from '../hooks';
import { IFileImage } from '../@types';
// import MyFancyRule from './components/my-fancy-rule.js';

export const ContainerBreadCrumbs = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'relative',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '20px' : '40px',
    width: props.isMobile ? '80vw' : '100%',
    textAlign: 'left',
  })
);

export interface IDocumentsPage {
  href: string;
  target: string;
  title: string;
  Body: string;
  dropZonePages: Array<IDropZonePages>;
}

export interface IDropZonePages {
  title: string;
  href: string;
  enable: boolean;
}

const DocumentsPage = (props: IPage<IDocumentsPage>) => {
  const data = props.pageContext;
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <LayoutProvider seo>
      <CenteredContainer>
        <ResponsiveContainer flexDirection='column'>
          <ContainerBreadCrumbs isMobile={isMobile}>
            <CannonBreadcrumbs
              pages={[
                { title: 'Home', href: '/' },
                { title: 'Support', href: '' },
                { title: `${data.title}`, href: `${data.href}` },
              ]}
            />
          </ContainerBreadCrumbs>
          <DocumentBody Body={data.Body} dropZonePages={data.dropZonePages} />
        </ResponsiveContainer>
      </CenteredContainer>
    </LayoutProvider>
  );
};

export default DocumentsPage;
